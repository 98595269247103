import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
`;
export const ContentImg = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 250px;
`;

export const Logo = styled.div`
  width: 400px;
  height: 50px;

  @media (max-width: 670px) {
    width: 350px;
    height: 20px;
  }
`;

export const ContentLogin = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

export const ButtonLogin = styled.button`
  display: flex;
  gap: 60px;

  @media  (max-width: 590px) {
  flex-direction: column;
  }
`;

export const ButtonPerson = styled.button`
  display: flex;
  width: 546px;
  height: 100px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #50700F;
  flex-direction: column;
  font-family: "poppins";

  &:nth-child(1) {
    background-color: #E1E7D6; 
  }

  &:nth-child(2) {
    background-color: #ffff; 
    border: solid 1px #E1E7D6;
  }

  &:nth-child(3) {
    background-color: #EDE2E2; 
  }

  @media (max-width: 420px) {
    width: 320px;
    height: 80px;
    font-size: 17px;
}

  @media (min-width: 421px) and (max-width: 590px) {
    width: 370px;
    height: 80px;
    font-size: 17px;
}

  @media (min-width: 591px) and (max-width: 670px) {
    display: flex;
    width: 170px;
    height: 80px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #50700F;
    flex-direction: column;
    font-family: "poppins";

  &:nth-child(1) {
    background-color: #E1E7D6; 
  }

  &:nth-child(2) {
    background-color: #ffff; 
    border: solid 1px #E1E7D6;
  }

  &:nth-child(3) {
    background-color: #EDE2E2; 
  }
}

  @media (min-width: 670px) and (max-width: 810px) {
    display: flex;
    width: 200px;
    height: 80px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #50700F;
    flex-direction: column;
    font-family: "poppins";

  &:nth-child(1) {
    background-color: #E1E7D6; 
  }

  &:nth-child(2) {
    background-color: #ffff; 
    border: solid 1px #E1E7D6;
  }

  &:nth-child(3) {
    background-color: #EDE2E2; 
  }
}

  @media (min-width: 700px) and (max-width: 810px) {
    display: flex;
    width: 200px;
    height: 80px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #50700F;
    flex-direction: column;
    font-family: "poppins";

  &:nth-child(1) {
    background-color: #E1E7D6; 
  }

  &:nth-child(2) {
    background-color: #ffff; 
    border: solid 1px #E1E7D6;
  }

  &:nth-child(3) {
    background-color: #EDE2E2; 
  }
}

  @media (min-width: 810px) and (max-width: 1024px) {
    display: flex;
    width: 250px;
    height: 80px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #50700F;
    flex-direction: column;
    font-family: "poppins";

  &:nth-child(1) {
    background-color: #E1E7D6; 
  }

  &:nth-child(2) {
    background-color: #ffff; 
    border: solid 1px #E1E7D6;
  }

  &:nth-child(3) {
    background-color: #EDE2E2; 
  }
}

  @media (min-width: 1024px) and (max-width: 1300px) {
    display: flex;
    width: 300px;
    height: 80px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #50700F;
    flex-direction: column;
    font-family: "poppins";

  &:nth-child(1) {
    background-color: #E1E7D6; 
  }

  &:nth-child(2) {
    background-color: #ffff; 
    border: solid 1px #E1E7D6;
  }

  &:nth-child(3) {
    background-color: #EDE2E2; 
  }
}

@media (min-width: 1300px) and (max-width: 1500px) {
    display: flex;
    width: 400px;
    height: 80px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #50700F;
    flex-direction: column;
    font-family: "poppins";

  &:nth-child(1) {
    background-color: #E1E7D6; 
  }

  &:nth-child(2) {
    background-color: #ffff; 
    border: solid 1px #E1E7D6;
  }

  &:nth-child(3) {
    background-color: #EDE2E2; 
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {
    display: flex;
    width: 490px;
    height: 80px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #50700F;
    flex-direction: column;
    font-family: "poppins";

  &:nth-child(1) {
    background-color: #E1E7D6; 
  }

  &:nth-child(2) {
    background-color: #ffff; 
    border: solid 1px #E1E7D6;
  }

  &:nth-child(3) {
    background-color: #EDE2E2; 
  }
}
`;

export const ButtonSatellite = styled.button`
  display: flex;
  width: 546px;
  height: 100px;
  background-color: #45B7FE;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: white;
  flex-direction: column;
  font-family: "poppins";

  @media (max-width: 420px) {
    width: 320px;
    height: 80px;
    font-size: 17px;
  }

  @media (min-width: 420px) and (max-width: 590px) {
    display: flex;
    width: 370px;
    height: 80px;
    font-size: 18px;
  }
  
  @media (min-width: 591px) and (max-width: 700px) {
    display: flex;
    width: 170px;
    height: 80px;
    background-color: #45B7FE;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: white;
    flex-direction: column;
    font-family: "poppins";
  }

  @media (min-width: 700px) and (max-width: 810px) {
    display: flex;
    width: 200px;
    height: 80px;
    background-color: #45B7FE;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: white;
    flex-direction: column;
    font-family: "poppins";
  }

  @media (min-width: 810px) and (max-width: 1024px) {
    display: flex;
    width: 250px;
    height: 80px;
    background-color: #45B7FE;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: white;
    flex-direction: column;
    font-family: "poppins";
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    display: flex;
    width: 300px;
    height: 80px;
    background-color: #45B7FE;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
    flex-direction: column;
    font-family: "poppins";
  }
  
  @media (min-width: 1300px) and (max-width: 1500px) {
    display: flex;
    width: 400px;
    height: 80px;
    background-color: #45B7FE;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
    flex-direction: column;
    font-family: "poppins";
  }

  @media (min-width: 1500px) and (max-width: 1700px) {
    display: flex;
    width: 490px;
    height: 80px;
    background-color: #45B7FE;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
    flex-direction: column;
    font-family: "poppins";
  }
`;

